import React, { useEffect, useState } from 'react';
import './house.css';
import { useData } from './DataContext';

const Houses = React.memo(() => {
  const { progress } = useData();
  const [currentHouse, setCurrentHouse] = useState(null);
  const [nextHouse, setNextHouse] = useState(null);

  useEffect(() => {
    setCurrentHouse(progress.current_level);
    setNextHouse(progress.next_level);
  }, [progress]);

  return (
    <div className="houses">
      <div className="house">
        <img src={currentHouse?.image} alt={currentHouse?.title || 'Standard house'} />
        <p>{currentHouse?.title || 'Standard house'}</p>
      </div>
      <div className="repost">
        <img src="/images/repost.svg" alt="Repost" />
      </div>
      <div className="house">
        <img src={nextHouse?.image || '/images/loading.png'} alt={nextHouse?.title || 'Modern house'} />
        <p>{nextHouse?.title || 'Modern house'}</p>
      </div>
    </div>
  );
});

export default Houses;
