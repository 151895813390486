import React, {useState, useEffect, memo, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from './DataContext';
import apiServiceInstance from '../apiService';

const SproutCard = memo(() => {
  const navigate = useNavigate();
  const { token, hasReferrals, fetchDailySprout, dailySprout, setDailySprout, handleSproutTap } = useData();
  const [refreshKey, setRefreshKey] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { sprout } = dailySprout || {};
  
  const handlePlayClick = useCallback(async () => {
    if (!hasReferrals) return;
  
    try {
      const response = await apiServiceInstance.getUserSprout(token, { headers: { 'Cache-Control': 'no-cache' } });
      
      const { data } = response;
  
      if (data) {
        await fetchDailySprout();
        setRefreshKey(prevKey => prevKey + 1);
      }
  
      if (token) {
        navigate(`/game?token=${token}`);
      } else {
        console.error('Token is missing');
      }

    } catch (error) {
      console.error('Error fetching daily sprout data:', error);
    }
  }, [hasReferrals, token, fetchDailySprout, navigate]);
  
  return (
    <div className="cardsHolder" key={refreshKey}>
      <div className="cardCom">
        <div className="nameOf">
          <img className='icon' src='/images/plant 1.svg' alt='Sprout Icon' />
          <div className="nameBal">
            <p>Daily Sprout</p>
            {hasReferrals ? (
              <p className="sproutDay">Day {dailySprout?.sprout?.day || 'Loading...'}</p>
            ) : (
              <p className="sproutDay invite">Invite 3 friends for game 🌳</p>
            )}
          </div>
        </div>
        <button
          className='gamesBtn'
          onClick={handlePlayClick}
          disabled={!hasReferrals}
          style={{ opacity: hasReferrals ? 1 : 0.5 }}
        >
          {hasReferrals ? 'Play' : 'Locked'}
        </button>
      </div>
    </div>
  );
  
});

export default SproutCard;
