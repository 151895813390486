import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const getUserProfile = (token) => {
  const url = `${apiUrl}/api/user/profile/?token=${token}`;
  return axios.get(url);
};

const getUserReferrals = (token) => {
  const url = `${apiUrl}/api/user/referrals/?token=${token}`;
  return axios.get(url);
};

const getUserProgress = (token) => {
  const url = `${apiUrl}/api/level/user_data/?token=${token}`;
  return axios.get(url);
};

const getUserTask = (token) => {
  const url = `${apiUrl}/api/task?${token}`;
  return axios.get(url);
};

const getUserClaimed = (token, id) => {
  const url = `${apiUrl}/api/task/claimed/?token=${token}&task_id=${id}`;
  return axios.get(url);
};

const claimTask = (token, taskId) => {
  const url = `${apiUrl}/api/task/claim/?token=${token}&task_id=${taskId}`;
  return axios.post(url);
};

const getUserBooster = (token) => {
  const url = `${apiUrl}/api/booster?token=${token}`;
  return axios.get(url)
};

const getUserBoosterUpgrade = (token, booster_type) => {
  const url = `${apiUrl}/api/booster/upgrade/?token=${token}&booster_type=${booster_type}`;
  return axios.post(url);
};

const getUserTap = (token) => {
  const url = `${apiUrl}/api/tap/amount/?token=${token}`;
  return axios.get(url);
};

const getUserSproutTap = (token) => {
  const url = `${apiUrl}/api/daily_sprout/tap/?token=${token}`;
  return axios.get(url)
};

const getUserSprout = (token) => {
  const url = `${apiUrl}/api/daily_sprout?token=${token}`;
  return axios.get(url);
};

const getUserTapEnergy = (token) => {
  const url = `${apiUrl}/api/tap/energy/?token=${token}`;
  return axios.get(url);
};

const apiServiceInstance = {
  getUserProfile,
  getUserReferrals,
  getUserProgress,
  getUserTask,
  getUserBooster,
  getUserTap,
  claimTask,
  getUserBoosterUpgrade,
  getUserSprout,
  getUserSproutTap,
  getUserClaimed,
  getUserTapEnergy,
};

export default apiServiceInstance;