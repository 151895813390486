import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import apiServiceInstance from '../apiService';
import { useDispatch } from 'react-redux';
import { updateBalance, setEnergy, setEnergyLimit, setPower } from '../slices/energyBalanceSlice';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [progress, setProgress] = useState(null);
  const [referrals, setReferrals] = useState({});
  const [task, setTask] = useState(null);
  const [claim, setClaim] = useState(null);
  const [dailySprout, setSprout] = useState(null);
  const [claimed, setClaimed] = useState({});
  const [booster, setBooster] = useState(null);
  const [tap, setTap] = useState(null);
  const [tapEnergy, setTapEnergy] = useState(null);
  const [sproutTap, setSproutTap] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [hasReferrals, setHasReferrals] = useState(true);
  const [hasFetchedTap, setHasFetchedTap] = useState(false);

  const dispatch = useDispatch(); // Инициализируем dispatch
  const isFetchingDailySprout = useRef(false); 
  const isFetchingTap = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const urlToken = urlParams.get('token');
      setToken(urlToken);

      if (urlToken) {
        try {
          const [
            userResponse,
            referralsResponse,
            progressResponse,
            taskResponse,
            boosterResponse,
            claimedResponse,
            tapEnergyResponse,
          ] = await Promise.all([
            apiServiceInstance.getUserProfile(urlToken),
            apiServiceInstance.getUserReferrals(urlToken),
            apiServiceInstance.getUserProgress(urlToken),
            apiServiceInstance.getUserTask(urlToken),
            apiServiceInstance.getUserBooster(urlToken),
            apiServiceInstance.getUserClaimed(urlToken),
            apiServiceInstance.getUserTapEnergy(urlToken)
          ]);

          if (userResponse?.data) {
            setUserData(userResponse.data);
            dispatch(updateBalance(userResponse.data.balance)); // Обновляем Redux
          }
          if (referralsResponse?.data) setReferrals(referralsResponse.data);
          if (progressResponse?.data) setProgress(progressResponse.data);
          if (taskResponse?.data) setTask(taskResponse.data);
          if (boosterResponse?.data) {
            setBooster(boosterResponse.data);
            dispatch(setEnergyLimit(boosterResponse.data.energy_limit.energy)); // Обновляем Redux
            dispatch(setPower(boosterResponse.data.multi_tap.power)); // Обновляем Redux
          }
          if (tapEnergyResponse?.data) {
            setTapEnergy(tapEnergyResponse.data);
            dispatch(setEnergy(tapEnergyResponse.data)); // Обновляем Redux
          }

          if (!claimedResponse?.data) {
            throw new Error('Claimed data is missing');
          }

          const claimedData = claimedResponse.data.reduce((acc, claim) => {
            acc[claim.task_id] = 'Claimed';
            return acc;
          }, {});
          setClaimed(claimedData);

          if (referralsResponse?.data && referralsResponse.data.total_refs !== undefined) {
            setHasReferrals(referralsResponse.data.total_refs >= 3);
          } else {
            setHasReferrals(false);
          }

        } catch (fetchError) {
          setError('Failed to fetch data');
          console.error('Failed to fetch data:', fetchError);
        } finally {
          setLoading(false);
        }
      } else {
        setError('Token not found');
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const fetchDailySprout = useCallback(async () => {
    if (isFetchingDailySprout.current || !token || !hasReferrals) return;

    isFetchingDailySprout.current = true;

    try {
      const response = await apiServiceInstance.getUserSprout(token);
      const { data } = response;

      if (data?.detail === 'not_enough_referrals') {
        setHasReferrals(false);
        setError('Not enough referrals');
      } else if (data) {
        setSprout(data);
      }
    } catch (error) {
      console.error('Failed to fetch daily sprout data:', error);
      setError('Failed to fetch daily sprout data');
    } finally {
      isFetchingDailySprout.current = false; 
    }
  }, [token, hasReferrals]);

  const fetchTap = useCallback(async () => {
    if (isFetchingTap.current || !token || hasFetchedTap) return;
    
    isFetchingTap.current = true;

    try {
      const response = await apiServiceInstance.getUserTap(token);
      if (response?.data) setTap(response.data);
    } catch (error) {
      console.error('Failed to fetch tap data:', error);
      setError('Failed to fetch tap data');
    } finally {
      isFetchingTap.current = false;
      setHasFetchedTap(true);
    }
  }, [token, hasFetchedTap]);

  useEffect(() => {
    if (token && hasReferrals) {
      fetchDailySprout();
    }
  }, [token, hasReferrals, fetchDailySprout]);

  const handleSproutTap = async () => {
    if (!token) return;

    try {
      const response = await apiServiceInstance.getUserSproutTap(token);
      if (response?.data) setSproutTap(response.data);
    } catch (error) {
      console.error('Failed to fetch sprout tap data:', error);
      setError('Failed to fetch sprout tap data');
    }
  };

  const handleClaimTask = async (taskId) => {
    if (!token) return;

    try {
      const response = await apiServiceInstance.claimTask(token, taskId);
      if (response?.data) {
        setClaimed((prev) => ({
          ...prev,
          [taskId]: 'Claimed'
        })); // Обновляем состояние claimed
      }
    } catch (error) {
      console.error('Failed to claim task:', error);
      setError('Failed to claim task');
    }
  };

  return (
    <DataContext.Provider
      value={{
        userData,
        setUserData,
        referrals,
        setReferrals,
        progress,
        setProgress,
        task,
        setTask,
        booster,
        setBooster,
        tap,
        setTap,
        claim,
        setClaim,
        claimed,
        setClaimed,
        dailySprout,
        setSprout,
        sproutTap,
        handleSproutTap,
        handleClaimTask,
        fetchDailySprout,
        fetchTap,
        loading,
        error,
        token,
        hasReferrals,
        setTapEnergy,
        tapEnergy,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
