import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  progress: 1,
  energy: 1,
  wait_time: 1,
  is_rewarded: false,
};

const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    setProgress(state, action) {
      state.progress = action.payload;
    },
    incrementProgress(state, action) {
      state.progress += action.payload;
      if (state.progress > 100) {
        state.progress = 100;
      }
    },
    setEnergy(state, action) {
      state.energy = action.payload;
    },
    setWaitTime(state, action) {
      state.wait_time = action.payload;
    },
    setRewarded(state, action) {
      state.is_rewarded = action.payload;
    },
  },
});

export const { setProgress, incrementProgress, setEnergy, setWaitTime, setRewarded } = progressSlice.actions;

export default progressSlice.reducer;
