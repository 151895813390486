import React, { useEffect } from 'react';
import SocMediaCard from '../components/SocMediaCard';
import DailySprout from '../components/DailySprout';
import Booster from '../components/Booster';
import SproutCard from '../components/SproutCard';
import { useDispatch, useSelector } from 'react-redux';
import { updateBalance } from '../slices/energyBalanceSlice';


function TasksPage() {
  const dispatch = useDispatch();
  const reduxBalance = useSelector((state) => state.energyBalance.balance);


  useEffect(() => {
   
     dispatch(updateBalance(reduxBalance));
    
  }, [ dispatch]);

  useEffect(() => {
    console.log('Current Balance from Redux in BoostersPage:', reduxBalance);
    console.log(reduxBalance)
  }, [reduxBalance]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <div className="TasksPage">
      <div className="background">
        <img src="/images/background.png" alt="Background" />
      </div>
      
        <h1>Earn more Build!</h1>
        <p className="subscribe">Tasks:</p>
       <SocMediaCard />
      
       <p className="subscribe games">Games:</p>
       <SproutCard />
    </div>
  );
}

export default TasksPage;
