import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Booster from '../components/Booster';
import Score from '../components/Score';
import { updateBalance } from '../slices/energyBalanceSlice';
import { useData } from '../components/DataContext';


const BoostersPage = React.memo(() => {
  const dispatch = useDispatch();
  const reduxBalance = useSelector((state) => state.energyBalance.balance);


  useEffect(() => {
   
     dispatch(updateBalance(reduxBalance));
    
  }, [ dispatch]);

  useEffect(() => {
    console.log('Current Balance from Redux in BoostersPage:', reduxBalance);
  }, [reduxBalance]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="BoostersPage">
      <div className="background">
        <img src="/images/background.png" alt="Background" />
      </div>
     
        <div>
          <div className='balance'>
            <h2>Your Balance</h2>
            <div className='currentBalance'>
              <h1>{typeof reduxBalance === 'number' ? reduxBalance : 'No balance available'}</h1>
              <img src='/images/coin.svg' alt='Coin' />
            </div>
          </div>
          <p className="subscribe">Boosters:</p>
          <Booster />
          <Score />
        </div>
      
    </div>
  );
});

export default BoostersPage;
