import React, { useEffect } from "react";
import DailySprout from "../components/DailySprout";


const GamePage = () => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
    }, []);
  
    return(
        <>  
            <div className="background">
                <img src="/images/sproutBack.png" alt="Background" loading="lazy" />
            </div>
            <DailySprout />
        </>
    );
}

export default GamePage;
