import React, { useState, useEffect } from 'react';
import { useData } from './DataContext';
import { useDispatch, useSelector } from 'react-redux';
import { updateBalance } from '../slices/energyBalanceSlice';

const SocMediaCard = React.memo(() => {
  const { task, claimed, handleClaimTask } = useData();
  const [tasks, setTasks] = useState([]);
  const [claimStatus, setClaimStatus] = useState(() => {
    const savedStatus = localStorage.getItem('claimStatus');
    return savedStatus ? JSON.parse(savedStatus) : {};
  });
  const [timeOpened, setTimeOpened] = useState(() => {
    const savedTimes = localStorage.getItem('timeOpened');
    return savedTimes ? JSON.parse(savedTimes) : {};
  });
  const dispatch = useDispatch();
  const reduxBalance = useSelector((state) => state.energyBalance.balance);

  useEffect(() => {
    if (task) {
      setTasks(task);

      const initialStatus = task.reduce((acc, t) => {
        acc[t.id] = claimed[t.id] || t.status || 'Open';
        return acc;
      }, {});

      setClaimStatus((prevStatus) => ({
        ...initialStatus,
        ...prevStatus,
      }));
    }
  }, [task, claimed]);

  // Save claimStatus and timeOpened to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('claimStatus', JSON.stringify(claimStatus));
    localStorage.setItem('timeOpened', JSON.stringify(timeOpened));
  }, [claimStatus, timeOpened]);

  // Check if 10 minutes have passed and update the claim status accordingly
  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedClaimStatus = { ...claimStatus };

      tasks.forEach((t) => {
        const openTime = timeOpened[t.id];
        if (openTime) {
          const currentTime = Date.now();
          const elapsedTime = currentTime - openTime;

          // If 24 hours have passed and the status is still "Pending", change to "Claim"
          if (elapsedTime >= 60 * 60 * 1000 * 24 && updatedClaimStatus[t.id] === 'Pending') {
            updatedClaimStatus[t.id] = 'Claim';
            setClaimStatus(updatedClaimStatus);
          }
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [tasks, claimStatus, timeOpened]);

  const handleButtonClick = async (taskId, status) => {
    const task = tasks.find(t => t.id === taskId);

    if (status === 'Open' || status === 'Pending') {
      window.open(task.link, '_blank');
      
      
      if (!timeOpened[taskId]) {
        setTimeOpened((prev) => ({ ...prev, [taskId]: Date.now() }));
        setClaimStatus(prev => ({ ...prev, [taskId]: 'Pending' }));
      }
    } else if (status === 'Claim') {
      await handleClaimTask(taskId);
      setClaimStatus(prev => ({ ...prev, [taskId]: 'Claimed' }));

      const reward = task.reward ? task.reward : 0;
      dispatch(updateBalance(reduxBalance + reward));
    }
  };

  const getButtonLabel = (taskId) => {
    const status = claimStatus[taskId];

    if (status === 'Claimed') {
      return 'Claimed';
    } else if (status === 'Claim') {
      return 'Claim';
    } else if (status === 'Pending') {
      return 'Checking...';
    } else {
      return 'Open';
    }
  };

  return (
    <>
      {tasks.length > 0 ? (
        tasks.map(t => (
          <div className="cardsHolder" key={t.id}>
            <div className="cardCom">
              <div className="nameOf">
                <img className='icon' src={t.image || '/images/flash.svg'} alt={t.title || 'Task Image'} />
                <div className="nameBal">
                  <p>{t.title || 'No Title'}</p>
                  <div className='prizeAmount'>
                    <img src='/images/coin.svg' alt='Coin' />
                    <p>{t.reward}</p>
                  </div>
                </div>
              </div>
              <button
                className='socBtn'
                style={{ opacity: claimStatus[t.id] === 'Claimed' ? 0.5 : 1 }}
                onClick={() => handleButtonClick(t.id, claimStatus[t.id])}
                disabled={claimStatus[t.id] === 'Claimed'}
              >
                {getButtonLabel(t.id)}
              </button>
            </div>
          </div>
        ))
      ) : (
        <p>No tasks available.</p>
      )}
    </>
  );
});

export default SocMediaCard;
