import React, { useEffect } from 'react';
import House from '../components/House';
import Referrals from '../components/Referrals';
import UserInfo from '../components/UserInfo';
import { useDispatch, useSelector } from 'react-redux';
import { updateBalance } from '../slices/energyBalanceSlice';
import { useData } from '../components/DataContext';

function BuiltPage() {
  const { tapEnergy } = useData(); 
  const dispatch = useDispatch();
  const reduxBalance = useSelector((state) => state.energyBalance.balance);

  useEffect(() => {
    dispatch(updateBalance(reduxBalance));
  }, [dispatch, reduxBalance]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="builtPage">
      <div className="background">
        <img src="/images/background.png" alt="Background" />
      </div>
      <UserInfo />
      <Referrals />
      <House />
      <div className='exmar'></div>
    </div>
  );
}

export default BuiltPage;
