import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useData } from './DataContext';

function Score() {
  const { userData } = useData();
  const energy = useSelector((state) => state.energyBalance.energy);
  const energyLimit = useSelector((state) => state.energyBalance.energyLimit);
  const power = useSelector((state) => state.energyBalance.power);
  const {tapEnergy, setTapEnergy} = useData(null)

  const [currentEnergy, setCurrentEnergy] = useState(energy);

  useEffect(() => {
    setCurrentEnergy(energy);
    setTapEnergy(energy);
  }, [energy]);

  useEffect(() => {
    if (power <= 0) return;

    const interval = setInterval(() => {
      setCurrentEnergy((prevEnergy) => Math.min(prevEnergy + power, energyLimit));
    }, 1000);

    return () => clearInterval(interval);
  }, [power, energyLimit]);

  return (
    <div className="score">
      <div className="maxScore">
        <img src="/images/flash.svg" alt="Max Score" />
        <p>{currentEnergy}</p>
        <p>/</p>
        <p>{energyLimit}</p>
      </div>
      <div className="perTap">
        <p>{power}</p>
        <p>/</p>
        <p>per Tap</p>
      </div>
    </div>
  );
}

export default Score;
