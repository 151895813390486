import React, { useState, useEffect } from 'react';
import { useData } from './DataContext';
import Notification from './notification/Notification'; // Import the Notification component

const Referrals = React.memo(() => {
  const { userData, referrals } = useData();

  const [firstLevelRefs, setFirstLevelRefs] = useState(0);
  const [secondLevelRefs, setSecondLevelRefs] = useState(0);
  const [copySuccess, setCopySuccess] = useState(false);
  const [error] = useState(null);
  const [link, setLink] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      setFirstLevelRefs(userData.refs || 0);
      setSecondLevelRefs(userData.second_refs || 0);
    };

    fetchUserData();
  }, [userData]);

  useEffect(() => {
    const fetchReferralLink = async () => {
      try {
        const data = referrals;
        setLink(data.link || '');
      } catch (error) {
        console.error('Error fetching referral link:', error);
      }
    };

    fetchReferralLink();
  }, [referrals]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(() => {
      setCopySuccess(true);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleNotificationClose = () => {
    setCopySuccess(false);
  };

  const sendInvite = () => {
    const message = `\n \n 👆Your invite is here\n \n I'm gifting you an invite to my building 🎁🛖🐹. Grab it now and get in early for the hottest build-to-earn game on Telegram — BuildCoin🏠. Be one of the first and bring your friends 👨‍🦱👳‍♂️🧔‍♂️🙎‍♀️👩‍🍳. \n \n 🎁 Claim your free house 🐹 \n 🦸‍♂️ Build your house of friends \n 🔥 3-level referral program`;
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(link)}&text=${encodeURIComponent(message)}`;
    window.open(telegramUrl, '_blank');
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="ref">
      <div className="refBlocks">
        <div className="left">
          <div className="humanIcon">
            <div className="blockIcon">
              <img src="/images/human-icon.svg" alt="Icon" />
            </div>
            <div className="amountMembers">
              <p>{firstLevelRefs}</p>
            </div>
          </div>
          <div className="text">
            <p>First Level</p>
            <p>Referrals</p>
          </div>
        </div>
        <div className="right">
          <div className="humanIcon">
            <div className="blockIcon">
              <img src="/images/human-icon.svg" alt="Icon" />
            </div>
            <div className="amountMembers">
              <p>{secondLevelRefs}</p>
            </div>
          </div>
          <div className="text">
            <p>2nd, 3rd Level</p>
            <p>Referral Friends</p>
          </div>
        </div>
      </div>

      <div className="refLink">
        <input readOnly id="refLinkInput" type="text" value={link} />
        <img className="copyButton" src="/images/copyButton.svg" alt="Copy" onClick={copyToClipboard} />
      </div>

      <button className="inviteButton" onClick={sendInvite}>
        <p>Invite friends</p>
        <img src="/images/repost.svg" alt="Invite" />
      </button>

      {/* Display Notification when copySuccess is true */}
      {copySuccess && <Notification message="Copied!" onClose={handleNotificationClose} />}
    </div>
  );
});

export default Referrals;
