import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBalance, setEnergy, setEnergyLimit, setPower } from '../slices/energyBalanceSlice';
import { useData } from './DataContext';

const UserInfo = React.memo(() => {
  const dispatch = useDispatch();
  const { userData, tapEnergy, booster } = useData();
  const reduxBalance = useSelector((state) => state.energyBalance.balance);

  useEffect(() => {
    if (userData && userData.balance !== reduxBalance) {
      dispatch(updateBalance(userData.balance));
    }
  }, [userData, reduxBalance, dispatch]);

  useEffect(() => {
    if (tapEnergy) {
      dispatch(setEnergy(tapEnergy));
    }
  }, [tapEnergy, dispatch]);

  useEffect(() => {
    if (booster) {
      dispatch(setEnergyLimit(booster.energy_limit.energy));
      dispatch(setPower(booster.multi_tap.power));
    }
  }, [booster, dispatch]);

  return (
    <div className="userInfoContainer">
      {userData ? (
        <div className="userInfo">
          <div className='imgName'>
            <div className="userImg">
              <img src={userData.avatar || '/images/usernone.png'} alt="User" />
            </div>
            <div className="username">
              <p>{userData.name || 'No name'}</p>
            </div>
          </div>
          <div className="balance">
            <p>{reduxBalance}</p> {/* Баланс из Redux */}
            <img src='/images/coin.svg' alt='Coin'/>
          </div>
        </div>
      ) : (
        <div>No user data available</div>
      )}
    </div>
  );
});

export default UserInfo;
