import React from 'react';


function InviteBlock() {
  return (
    <div className="inviteBlock">
      <h2>Invite your friends!</h2>
      <p>More friends you invite, more income you get!</p>
    </div>
  );
}

export default InviteBlock;
