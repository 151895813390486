import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  energy: 0,
  energyLimit: 100,
  balance: 100,
  power: 1,
};

const energyBalanceSlice = createSlice({
  name: 'energyBalance',
  initialState,
  reducers: {
    setEnergy: (state, action) => {
      state.energy = action.payload;
    },
    incrementEnergy: (state, action) => {
      state.energy = Math.min(state.energy + action.payload, state.energyLimit);
    },
    decrementEnergy: (state, action) => {
      state.energy = Math.max(state.energy - action.payload, 0);
    },
    updateBalance: (state, action) => {
      state.balance = action.payload;
    },
    setEnergyLimit: (state, action) => {
      state.energyLimit = action.payload;
    },
    setPower: (state, action) => {
      state.power = action.payload;
    },
  },
});

export const {
  setEnergy,
  incrementEnergy,
  decrementEnergy,
  updateBalance,
  setEnergyLimit,
  setPower,
} = energyBalanceSlice.actions;

export default energyBalanceSlice.reducer;
