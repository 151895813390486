import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import apiServiceInstance from '../apiService';
import { useData } from './DataContext';


const NavigationBar = React.memo(() => {
  const { token } = useData();
  const [activePath, setActivePath] = useState('/');
  const handleClick = (path) => {
    setActivePath(path);
  };

  return (
    <div className="navigationBar">
      
      <Link to={`/?token=${token}`} onClick={() => handleClick('/')}>
        <div className={`nav-item ${activePath === '/' ? 'active' : ''}`}>
        <img src="/images/built.svg" alt="Built" />
        <p>Build</p>
        </div>
      </Link>
     
      <Link to={`/referrals?token=${token}`} onClick={() => handleClick('/referrals')}>
        <div className={`nav-item ${activePath === '/referrals' ? 'active' : ''}`}>
          <img src="/images/refferals.svg" alt="Referrals" />
          <p>Referrals</p>
        </div>
      </Link>
      <Link to={`/built?token=${token}`} onClick={() => handleClick('/built')}>
        <div className={`nav-item ${activePath === '/built' ? 'active' : ''}`}>
          
          <img src="/images/tasks.svg" alt="Tasks" />
         
          <p>Tasks</p>
        </div>
      </Link>
      <Link to={`/boosters?token=${token}`} onClick={() => handleClick('/boosters')}>
        <div className={`nav-item ${activePath === '/boosters' ? 'active' : ''}`}>
          
          <img src="/images/boosters.svg" alt="boosters" />
         
          <p>Boosts</p>
        </div>
      </Link>
      
      
    </div>
  );
});

export default NavigationBar;