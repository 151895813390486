import React, { useState, useEffect } from 'react';
import { useData } from './DataContext';

const FriendCard = React.memo(() => {
  const { referrals } = useData();
  const [friends, setFriends] = useState([]);

  useEffect(() => {
    setFriends(referrals.friends);
  }, [referrals]);

  return (
    <>
      {friends.length > 0 ? (
        friends.map(friend => (
          <div className="friendCard" key={friend.name}>
            <div className="friendInfo">
              <div className="friendImg">
                <img src={friend.avatar || '/images/usernone.png'} alt="Friend Avatar" />
              </div>
              <div className="friendsName">
                {friend.name}
              </div>
            </div>
            <div className="friendBalance">
              <div className='level'>
                <p>LVL.</p>
                {friend.level ? friend.level.level : 'Unknown'}
              </div>
              <div className="coinImage">
                <img src='/images/whiteHuman.svg' alt="Coin" />
              </div>
              <div className="coinAmount">
                {friend.refs}
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No friends found.</p>
      )}
    </>
  );
});

export default FriendCard;
