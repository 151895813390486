import React from 'react';
import InviteBlock from '../components/InviteBlock';
import ProgressBar from '../components/ProgressBar';
import Referrals from '../components/Referrals';
import Houses from '../components/Houses';
import FriendCard from '../components/FriendCard';



function RefferalsPage() {
  return (
    <div className="builtPage referrals">
      <div className="background">
        <img src="/images/background.png" alt="Background" />
      </div>
      <InviteBlock />
      <ProgressBar />
      <Houses />
      <Referrals />
      <p className="friendListCaption">List of your friends</p>
      <div className='friendCards'>
        <FriendCard />
      </div>
    </div>
  );
}

export default RefferalsPage;
