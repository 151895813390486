import { configureStore } from '@reduxjs/toolkit';
import energyBalanceReducer from './slices/energyBalanceSlice';
import progressReducer from './slices/progressSlice'

export default configureStore({
  reducer: {
    energyBalance: energyBalanceReducer,
    progress: progressReducer,
  },
  
});
