import React, { useEffect, useState } from 'react';

const LoadingPage = ({ isLoaded }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    const duration = 1000; 
    const increment = 100 / (duration / 5); 

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + increment;
        return newProgress >= 95 ? 95 : newProgress;
      });
    }, 5);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isLoaded && progress < 100) {
      setProgress(100); 
    }
  }, [isLoaded, progress]);

  return (
    <div className="loadingPage">
      <div className="background">
        <img src="/images/background.png" alt="Background" />
      </div>
      <div className="loading">
        <div className="loadingImg">
          <img src="/images/loading.png" alt="Loading" />
        </div>
        <div className="loadingText">
          <img src="/images/BuildCoin.png" alt="BuildCoin" />
        </div>
        <div className="loadingProgressBar">
          <div className="loadingBar" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
