import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  decrementEnergy,
  incrementEnergy,
  setEnergy,
  setEnergyLimit,
  setPower,
  updateBalance,
} from '../slices/energyBalanceSlice';
import { useData } from './DataContext';
import axios from 'axios';
import './house.css';
import apiServiceInstance from '../apiService';

const House = React.memo(() => {
  const { booster, progress, token, userData, tapEnergy, setTapEnergy } = useData();
  const level = userData || {};
  const dispatch = useDispatch();

  const energy = useSelector((state) => state.energyBalance.energy);
  const energyLimit = useSelector((state) => state.energyBalance.energyLimit);
  const power = useSelector((state) => state.energyBalance.power);
  const balance = useSelector((state) => state.energyBalance.balance);
  const [currentEnergy, setCurrentEnergy] = useState(energy);

  const [plusOnes, setPlusOnes] = useState([]);
  const [coinClicked, setCoinClicked] = useState(false);
  const [tapEnergyAccumulated, setTapEnergyAccumulated] = useState(0);
  const [isTapping, setIsTapping] = useState(false);
  const houseRef = useRef(null);
  const [timeoutId, setTimeoutId] = useState(null); 

  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const energyThreshold = 0.003 * energyLimit;
 


  useEffect(() => {
    const initializeData = async () => {
      try {
        if (tapEnergy !== undefined) {
          dispatch(setEnergy(tapEnergy));
        }
  
        if (booster) {
          dispatch(setEnergyLimit(booster.energy_limit.energy));
          dispatch(setPower(booster.multi_tap.power));
        }
  
        if (userData) {
          dispatch(updateBalance(userData.balance));
        }
      } catch (error) {
        console.error('Error initializing data:', error);
      }
    };
  
    initializeData();
  }, [booster, userData, dispatch, tapEnergy]);

  useEffect(() => {
    if (!isTapping) {
      const energyInterval = setInterval(() => {
        dispatch(incrementEnergy(power));
      }, 1000);

      return () => clearInterval(energyInterval);
    }
  }, [isTapping, power, dispatch]);

  const tapEnergyRef = useRef(tapEnergyAccumulated);

useEffect(() => {
  tapEnergyRef.current = tapEnergyAccumulated;
}, [tapEnergyAccumulated]);

const sendTapAmount = useCallback(async () => {
  if (tapEnergyRef.current > 0) {
    try {
      console.log('Sending tap amount:', tapEnergyRef.current); 
      await axios.get('https://api.buildcoin.app/api/tap/amount/', {
        params: {
          token,
          amount: tapEnergyRef.current,
        },
      });

      setTapEnergyAccumulated(0);
    } catch (error) {
      console.error('Failed to send tap amount:', error);
    }
  }
}, [token]);

  

  const handleTap = useCallback(
    (tapCount, clientX, clientY) => {
      if (!houseRef.current || energy <= 0 || energy < energyThreshold) return;

      const rect = houseRef.current.getBoundingClientRect();
      const x = clientX - rect.left;
      const y = clientY - rect.top;

      const id = Date.now();
      setClickPosition({ x, y });
      setPlusOnes((prevPlusOnes) => [...prevPlusOnes, { id, x, y }]);

      setTimeout(() => {
        setPlusOnes((prevPlusOnes) => prevPlusOnes.filter((plusOne) => plusOne.id !== id));
      }, 1000);

      const energyConsumed = power * tapCount; 

      if (energy - energyConsumed < 0) {
        return; // 
      }

      dispatch(decrementEnergy(energyConsumed));
      dispatch(updateBalance(balance + energyConsumed));
      setTapEnergy(energy - energyConsumed);
      setCoinClicked(true);

      setTapEnergyAccumulated((prev) => prev + energyConsumed);

      // 
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(() => {
        sendTapAmount();
      }, 300);

      setTimeoutId(newTimeoutId); 

      setTimeout(() => {
        setCoinClicked(false);
      }, 200);

      setIsTapping(true);
    },
    [energy, power, energyThreshold, balance, dispatch, sendTapAmount, timeoutId]
  );

  const handleTouchStart = (e) => {
    const touches = e.touches;

    for (let i = 0; i < touches.length; i++) {
      handleTap(touches.length, touches[i].clientX, touches[i].clientY);
    }
  };
 

  useEffect(() => {
    if (isTapping) {
      const timeoutId = setTimeout(() => {
        setIsTapping(false);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [isTapping]);

  const { x, y } = clickPosition;
  const houseRect = houseRef.current?.getBoundingClientRect();
  const centerX = houseRect ? houseRect.width / 2 : 0;
  const centerY = houseRect ? houseRect.height / 2 : 0;

  const tiltX = ((x - centerX) / centerX) * 10;
  const tiltY = ((y - centerY) / centerY) * 10;

  return (
    <div className="container" style={{ position: 'relative' }}>
      <div
        className={`house main ${coinClicked ? 'coin-clicked' : ''}`}
        ref={houseRef}
        onTouchStart={handleTouchStart}
        style={{
          transform: `perspective(500px) rotateX(${tiltY}deg) rotateY(${tiltX}deg)`,
          transition: 'transform 0.2s ease',
        }}
      >
        <img
          src={userData.level.image}
          alt={level.title || 'Standard house'}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div className="score">
        <div className="maxScore">
          <img src="/images/flash.svg" alt="Max Score" />
          <p>{energy}</p>
          <p>/</p>
          <p>{energyLimit}</p>
        </div>
        <div className="perTap">
          <p>{power}</p>
          <p>/</p>
          <p>per Tap</p>
        </div>
      </div>
      {plusOnes.map(({ id, x, y }) => (
        <div
          key={id}
          style={{
            position: 'absolute',
            top: `${y}px`,
            left: `${x}px`,
            fontSize: '32px',
            color: '#fff',
            animation: 'fadeOut 2s forwards',
            pointerEvents: 'none',
            fontWeight: '500',
            fontFamily: 'Kanit',
          }}
        >
          +{power}
        </div>
      ))}
      <style>{`
        @keyframes fadeOut {
          from {
            opacity: 1;
            transform: translateY(0);
          }
          to {
            opacity: 0;
            transform: translateY(-150px);
          }
        }

        @keyframes coinClick {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(2px); 
          }
          100% {
            transform: translateY(0);
          }
        }

        .coin-clicked {
          animation: coinClick 0.2s ease;
        }
      `}</style>
    </div>
  );
});

export default House;
