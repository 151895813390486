import React, { useState, useEffect } from 'react';
import { useData } from './DataContext';

const ProgressBar = () => {
  const { progress, referrals } = useData();

  const [currentLevel, setCurrentLevel] = useState(1);
  const [maxLevel, setMaxLevel] = useState(10);
  // const [currentProgress, setCurrentProgress] = useState(0);
  const [maxProgress, setMaxProgress] = useState(150);
  const [levelTitle, setLevelTitle] = useState('Standard house');
  const [error] = useState(null);
  const [totalRefs, setTotalRefs] = useState(() => {
    const savedTotalRefs = localStorage.getItem('totalRefs');
    return savedTotalRefs ? JSON.parse(savedTotalRefs) : 0;
  });

  useEffect(() => {
    const fetchData = async () => {
      setCurrentLevel(progress.current_level.level);
      setMaxLevel(progress.max_levels || 10);
      // setCurrentProgress(progress.current_level.refs);
      setMaxProgress(progress.current_level ? progress.current_level.refs : 150);
      setLevelTitle(progress.current_level.title || 'Standard house');
      setTotalRefs(referrals.total_refs);
    };

    fetchData();
  }, [referrals, progress,]);

  if (error) {
    return <div>{error}</div>;
  }

  const progressPercentage = (totalRefs / maxProgress) * 100;

  return (
    <div className="progressBar">
      <div className="levelInfo">
        <div className="levelName">
          <p>{levelTitle}</p>
        </div>
        <div className="levelNumber">
          <p>{totalRefs}</p>
          <p>/</p>
          <p>{maxProgress}</p>
        </div>
      </div>
      <div className="myProgress">
        <div className="myBar" style={{ width: `${progressPercentage}%` }}></div>
      </div>
      <div className="LvlNumber">
        <p className="Lvl">Lvl</p>
        <p>{currentLevel}</p>
        <p>/</p>
        <p>{maxLevel}</p>
      </div>
    </div>
  );
};

export default ProgressBar;
