import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import BuiltPage from './pages/BuiltPage';
import RefferalsPage from './pages/RefferalsPage';
import LoadingPage from './pages/LoadingPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DataProvider, useData } from './components/DataContext';
import TasksPage from './pages/TasksPage';
import BoostersPage from './pages/BoostersPage';
import GamePage from './pages/GamePage';


if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
    navigator.serviceWorker.register(swUrl).then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
  });
}

const queryClient = new QueryClient();



const AppContent = () => {
  const { loading } = useData();

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const webApp = window.Telegram.WebApp;

      webApp.ready();
      webApp.expand();
      webApp.disableClosingConfirmation();
      webApp.disableVerticalSwipes();
    }
  }, []);

  return (
    <div className="mainContent">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <Routes>
            <Route path="/" element={<BuiltPage />} />
            <Route path="/built" element={<TasksPage />} />
            <Route path="/referrals" element={<RefferalsPage />} />
            <Route path="/boosters" element={<BoostersPage />} />
            <Route path="/game" element={<GamePage/>} /> 
          </Routes>
          <NavigationBar />
        </>
      )}
    </div>
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <DataProvider>
          <AppContent />
        </DataProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
