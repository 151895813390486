import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBalance, setEnergyLimit, setPower, setEnergy } from '../slices/energyBalanceSlice'; // Import actions
import apiServiceInstance from '../apiService';
import Notification from './notification/Notification'; // Import Notification component
import { useData } from './DataContext';


const Booster = React.memo(() => {
  const [notification, setNotification] = useState(null); // State for notifications
  const { booster, setBooster, token } = useData();
  const dispatch = useDispatch();
  const reduxBalance = useSelector((state) => state.energyBalance.balance);
  const energy = useSelector((state) => state.energyBalance.energy);
  const energyLimit = useSelector((state) => state.energyBalance.energyLimit);

  if (!booster) {
    return <div>Loading...</div>;
  }

  const { next_multi_tap, next_energy_limit, multi_tap, energy_limit } = booster;

  const handleUpgrade = async (boosterType, price) => {
    try {
      if (reduxBalance >= price) {
        // Call API to upgrade booster
        const response = await apiServiceInstance.getUserBoosterUpgrade(token, boosterType);
        const updatedBooster = response.data;
        setBooster(updatedBooster); // Update local booster state
        
        // Update Redux state
        dispatch(updateBalance(reduxBalance - price));
        if (boosterType === 'multi') {
          dispatch(setPower(updatedBooster.multi_tap.power));
          setNotification('Multitap upgraded!'); // Set notification for multitap upgrade
        } else if (boosterType === 'energy') {
          dispatch(setEnergyLimit(updatedBooster.energy_limit.energy));
          dispatch(setEnergy(updatedBooster.energy_limit.energy)); // Set energy to maximum
          setNotification('Energy limit upgraded!'); // Set notification for energy limit upgrade
        }
      } else {
        console.error('Not enough balance to upgrade.');
        setNotification('Not enough balance to upgrade.'); // Set notification for insufficient balance
      }
    } catch (error) {
      console.error('Error upgrading booster:', error);
      setNotification('Error upgrading booster.'); // Set notification for error
    }
  };

  return (
    <div className="cardsHolder">
      {/* Notification */}
      {notification && (
        <Notification 
          message={notification} 
          onClose={() => setNotification(null)} 
        />
      )}

      {/* Multi Tap Card */}
      <div className="cardCom">
        <div className="nameOf">
          <img className='nameOfimg' src="/images/point 1.svg" alt="Booster Icon" />
          <div className="nameBal">
            <p>Multitap</p>
            <div className='prizeAmount'>
              <img src='/images/coin.svg' alt='Coin' />
              {next_multi_tap ? (
                <p>{next_multi_tap.price}</p>
              ) : (
                <p></p>
              )}
              <h4 className='prizeLevel'>
                {next_multi_tap ? `${next_multi_tap.level} lvl` : (multi_tap ? `${multi_tap.level} lvl` : '')}
              </h4>
            </div>
          </div>
        </div>
        <button
          className='socBtn booster'
          onClick={() => next_multi_tap ? handleUpgrade('multi', next_multi_tap.price) : null}
          disabled={!next_multi_tap}
          style={{ opacity: next_multi_tap ? 1 : 0.5 }}
        >
          {next_multi_tap ? 'Upgrade' : 'MAX LVL.'}
        </button>
      </div>

      {/* Energy Limit Card */}
      <div className="cardCom">
        <div className="nameOf">
          <img className='nameOfimg' src="/images/flash.svg" alt="Booster Icon" />
          <div className="nameBal">
            <p>Energy Limit</p>
            <div className='prizeAmount'>
              <img src='/images/coin.svg' alt='Coin' />
              {next_energy_limit ? (
                <p>{next_energy_limit.price}</p>
              ) : (
                <p></p>
              )}
              <h4 className='prizeLevel'>
                {next_energy_limit ? `${next_energy_limit.level} lvl` : (energy_limit ? `${energy_limit.level} lvl` : '')}
              </h4>
            </div>
          </div>
        </div>
        <button
          className='socBtn booster'
          onClick={() => next_energy_limit ? handleUpgrade('energy', next_energy_limit.price) : null}
          disabled={!next_energy_limit}
          style={{ opacity: next_energy_limit ? 1 : 0.5 }}
        >
          {next_energy_limit ? 'Upgrade' : 'MAX LVL.'}
        </button>
      </div>
    </div>
  );
});

export default Booster;
