import React, { useState, useRef, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { incrementProgress, setProgress } from '../slices/progressSlice';
import { useData } from './DataContext';

const DailySprout = memo(() => {
  const dispatch = useDispatch();
  const { dailySprout, handleSproutTap, fetchDailySprout } = useData(); 
  const { sprout, energy, wait_time, progress, is_rewarded } = dailySprout || {};

  const reduxProgress = useSelector((state) => state.progress.progress);

  useEffect(() => {
    fetchDailySprout();

    const intervalId = setInterval(() => {
      fetchDailySprout(); 
    }, 10000); 

    return () => clearInterval(intervalId); 
  }, [fetchDailySprout]);

  useEffect(() => {
    if (progress !== undefined) {
      dispatch(setProgress(progress));
    }
  }, [progress, dispatch]);

  const [score, setScore] = useState(0);
  const [plusOnes, setPlusOnes] = useState([]);
  const [remainingTaps, setRemainingTaps] = useState(0);
  const [waitTime, setWaitTime] = useState(null);
  const [isProcessingTap, setIsProcessingTap] = useState(false); // Новое состояние для блокировки тапов
  const sproutContainerRef = useRef(null);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  
  const parseDate = (dateString) => {
    if (!dateString) {
      console.error('wait_time is undefined or empty');
      return new Date();
    }

    try {
      const [date, time] = dateString.split(' ');
      const [day, month, year] = date.split('.');
      const formattedDateString = `20${year}-${month}-${day}T${time}Z`;
      return new Date(formattedDateString);
    } catch (error) {
      console.error('Error parsing date:', error);
      return new Date();
    }
  };

  useEffect(() => {
    if (is_rewarded) {
      setWaitTime(0);
      setRemainingTaps(0);
    } else if (energy <= 0 && !wait_time) {
      setWaitTime(14400); // 4 hours
    } else if (wait_time) {
      const waitTimeDate = parseDate(wait_time);
      const currentTime = new Date();
      const remainingTimeInSeconds = Math.floor((waitTimeDate - currentTime) / 1000);
      setWaitTime(Math.max(remainingTimeInSeconds, 0));
    } else {
      setWaitTime(0); 
    }
  }, [wait_time, energy, is_rewarded]);

  useEffect(() => {
    if (waitTime <= 0 && !is_rewarded) {
      setRemainingTaps(energy || 0);
      return;
    }

    const interval = setInterval(() => {
      setWaitTime((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [waitTime, energy, is_rewarded]);

  useEffect(() => {
    localStorage.setItem('remainingTaps', remainingTaps);
  }, [remainingTaps]);

  const handleTap = async (e) => {
    if (
      is_rewarded || 
      remainingTaps <= 0 || 
      waitTime > 0 || 
      reduxProgress >= sprout?.progress || 
      isProcessingTap // Блокировка на основе состояния isProcessingTap
    ) {
      return;
    }

    // Блокируем повторные нажатия, пока не завершится обработка
    setIsProcessingTap(true); 

    if (!sproutContainerRef.current) return;

    const rect = sproutContainerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setScore((prev) => prev + 10);
    
    try {
      await handleSproutTap();
      setRemainingTaps((prev) => Math.max(prev - 1, 0)); // Обновляем количество оставшихся тапов
      dispatch(incrementProgress(1)); // Инкремент прогресса в Redux
    } catch (error) {
      console.error("Ошибка при обработке тапа", error);
    }

    // Анимация для "+1"
    const id = Date.now();
    const imageSrc = '/images/coin.svg';
    setPlusOnes((prev) => [...prev, { id, x, y, imageSrc }]);

    setTimeout(() => {
      setPlusOnes((prev) => prev.filter((plusOne) => plusOne.id !== id));
    }, 1000);

    // Разблокировка после завершения
    setIsProcessingTap(false); 
  };

  if (!dailySprout) {
    return <div>Loading...</div>;
  }

  const isTapDisabled = is_rewarded || waitTime > 0 || reduxProgress >= sprout?.progress || remainingTaps <= 0;

  return (
    <>
      <div className="dailySprout">
        <h2>Daily Sprout</h2>
        <p>Day {sprout?.day}</p>
      </div>
      <div className="sproutInfo">
        {is_rewarded || reduxProgress === sprout?.progress ? (
          <div className="rewardMessage timer">
            <img src="/images/flash.svg" alt="Reward" />
            <p>Got reward<br></br> Come back tomorrow</p>
            <div className="increase">
              <p>{reduxProgress}</p>
              <p>/</p>
              <p>{sprout.progress}</p>
            </div>
          </div>
        ) : (
          <>
            {remainingTaps > 0 && waitTime <= 0 ? (
              <div className="maxScore game">
                <img src="/images/flash.svg" alt="Max Score" />
                <p>{remainingTaps}</p>
                <p>/</p>
                <p>{20}</p>
              </div>
            ) : waitTime > 0 ? (
              <div className="timer">
                <img src="/images/flash.svg" alt="Max Score" />
                <p>Wait: {formatTime(waitTime)}</p>
              </div>
            ) : (
              <div className="timer">
                <img src="/images/flash.svg" alt="Max Score" />
                <p>Wait: 4 Hours</p>
              </div>
            )}
            <div className="increase">
              <p>{reduxProgress}</p>
              <p>/</p>
              <p>{sprout.progress}</p>
            </div>
          </>
        )}
        <div className="rewardImg">
          <p>{sprout?.reward}</p>
          <img src="/images/coin.svg" alt="Reward" />
        </div>
      </div>
      <div
        className="sproutContainer"
        ref={sproutContainerRef}
        onClick={handleTap}
        style={{ 
          position: 'relative', 
          cursor: isTapDisabled ? 'not-allowed' : 'pointer',  
          pointerEvents: isTapDisabled ? 'none' : 'auto',  
        }}
      >
        <div className="sprout">
          <img src={sprout?.image} alt="Sprout" loading="lazy"/>
        </div>
        {plusOnes.map(({ id, x, y, imageSrc }) => (
          <div
            key={id}
            style={{
              position: 'absolute',
              top: `${y}px`,
              left: `${x}px`,
              display: 'flex',
              alignItems: 'center',
              animation: 'fadeOut 1s forwards',
              pointerEvents: 'none',
            }}
          >
            <span
              style={{
                fontSize: '32px',
                color: '#40293C',
                fontWeight: '500',
                fontFamily: 'Kanit',
              }}
            >
              +1
            </span>
            <img
              src={'/images/water.png'}
              alt="Coin"
              style={{
                width: '30px',
                height: '30px',
                marginLeft: '4px',
              }}
            />
          </div>
        ))}
        <style>
          {`@keyframes fadeOut {
            from {
              opacity: 1;
              transform: translateY(0);
            }
            to {
              opacity: 0;
              transform: translateY(-30px);
            }
          }`}
        </style>
      </div>
      <div className="bottomLay">
        <h1>Tap!</h1>
      </div>
    </>
  );
});

export default DailySprout;
